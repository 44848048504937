import API from "../../../api/index";

export default {
  getAccountList: params => {
    return API.requestGet({
      params,
      url: "son/getList"
    });
  },
  // 添加子账号
  add: params => {
    return API.requestPost({
      data: params,
      url: "son/add"
    });
  },
  // 编辑子账号
  edit: params => {
    return API.requestPost({
      data: params,
      url: "son/edi"
    });
  },
  // 获取角色选项
  roles: params => {
    return API.requestGet({
      params,
      url: "role/getRoles"
    });
  },
  del: params => {
    return API.requestPost({
      data: params,
      url: "son/del"
    });
  }
};
